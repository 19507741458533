import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { IMedia, MediaType, MediaFormat, ImageFormat, VideoFormat, MediaMimeTypes } from '@types';
import { ConfirmationModal, FormLabel, MediaViewer } from '@component';
import { ContentTypes } from '@http/enums';
import { ApiResponse } from '@api/types';
import { CCard, CProgress } from '@coreui/react-pro';
import { cilPlus } from '@coreui/icons';
import { apiClients } from '@api';
import CIcon from '@coreui/icons-react';
import storage from '@storage';
import Svg from '@svg';
import styles from './file-attachment.module.scss';

interface FileAttachmentProps {
  name: string;
  label?: string;
  description?: string | JSX.Element;
  accept?: MediaMimeTypes[];
  required?: boolean;
  readOnly?: boolean;
  hidden?: boolean;
  multiple?: boolean;
  maxLength?: number;
  minLength?: number;
  value?: any;
  datatype?: 'uuid' | 'json';
  /** *
  * @deprecated - удалить после полного перехода на SmartForms
  */
  fromForm?: boolean;
  draggable?: boolean;
}

export default function FileAttachment(props: FileAttachmentProps) {
  const dispatch = useDispatch();

  const [draggedItem, setDraggedItem] = useState<any>(null);
  const [viewerData, setViewerData] = useState<IMedia>({});
  const [visibleViewer, setVisibleViewer] = useState(false);
  const [visibleDelete, setVisibleDelete] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const [items, setItems] = useState(getMedias(props.value));
  const fileRef = useRef<HTMLInputElement>(null);
  const maxLength = useMemo(() => props.multiple ? props.maxLength ?? 5 : 1, [props.maxLength]);
  const datatype = useMemo(() => props.datatype ?? 'uuid', [props.datatype]);

  const [progress, setProgress] = useState(0);

  useEffect(() => {
    if (!uploading) {
      setProgress(0);
    }
  }, [uploading]);

  const acceptTypes = useMemo(() =>
      props.accept && props.accept.length > 0
        ? props.accept
        : ['image/png', 'image/jpeg', 'video/avi', 'video/mp4', 'video/mov']
    , [props.accept]);

  const accept = useMemo(() => acceptTypes.join(', '), [acceptTypes]);

  const onView = useCallback((item: IMedia, index: number, e: React.MouseEvent) => {
    if (isFog(e.target)) {
      setActiveIndex(index);
      setVisibleViewer(true);
    }
  }, []);

  const onDelete = useCallback(async (item?: IMedia) => {
    if (item) {
      setItems((prev) => prev.filter(x => x.fileId !== item.fileId).sort((a, b) => (a.order ?? 0) > (b.order ?? 0) ? 1 : -1));
      try {
        setDeleting(true);
        if (item._new === true) {
          const response = await apiClients.base.delete<ApiResponse>(`api/admin/file/${item.fileId}`);
          if (response.errorCode) {
            const error = `${response.errorCode}: ${response.errorMsg ?? 'Ошибка сервера'}`;
            dispatch(storage.toast.setError(error));
            return;
          }
        }
      } finally {
        setDeleting(false);
      }
    }
    setVisibleDelete(false);
  }, []);

  const onUploadProgress = useCallback((progressEvent: any) => {
    const progress = progressEvent.total !== 0
      ? (progressEvent.loaded / progressEvent.total) * 100
      : 0;
    setProgress(Math.floor(progress));
  }, []);

  const onUploadFile = useCallback(async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files && e.target.files[0];
    try {
      if (file) {
        if (!acceptTypes.includes(file.type)) {
          dispatch(storage.toast.setError('Неверный формат файла'));
          return;
        }

        setUploading(true);
        const data = new FormData();
        data.append('file', file, file.name);
        const headers = { 'Content-Type': ContentTypes.MultipartFormData };
        type FileResponse = ApiResponse & { id?: string, url?: string, mediaType?: MediaType };
        const response = await apiClients.base.post<FileResponse>('api/admin/file', data, {
          headers,
          onUploadProgress
        });
        if (response.errorCode) {
          const error = `${response.errorCode}: ${response.errorMsg ?? 'Ошибка сервера'}`;
          dispatch(storage.toast.setError(error));
          return;
        }
        setItems((prev) => {
          const newItems = [...prev];
          newItems.push({
            fileId: response.id,
            url: response.url,
            type: response.mediaType,
            _new: true,
            order: (prev?.sort((a, b) => (a.order ?? 0) > (b.order ?? 0) ? 1 : -1)[prev.length -1]?.order ?? 0) + 1 
          });
          return newItems.sort((a, b) => (a.order ?? 0) > (b.order ?? 0) ? 1 : -1);
        });
      }
    } finally {
      setUploading(false);
      if (e.target.value) {
        e.target.value = '';
      }
    }
  }, [onUploadProgress]);

  const getValue = useCallback((item: IMedia) => {
    switch (datatype) {
      case 'json':
        item.type = item.type ?? getMediaType(item.url);
        item.format = item.format ?? getMediaFormat(item.url);
        return JSON.stringify(item);
      default:
        return item?.fileId;
    }
  }, [datatype]);

  const handleDrop = (e: any, card: IMedia) => {
    e.preventDefault();

    const data = JSON.parse(e.dataTransfer.getData('text/plain'));

    const newDr: IMedia[] = [];
    items.forEach(a => {
        let buf = a.order;

        if (a.fileId == data.fileId) {
          buf = card.order;
        }
        if (a.fileId == card.fileId) {
          buf = data.order;
        }

        newDr.push({ ...a, order: buf });
    });

    setItems(newDr.sort((a, b) => (a.order ?? 0) > (b.order ?? 0) ? 1 : -1));
  };

  const handleDragStart = (e: any, card: IMedia) => {
    e.dataTransfer.setData('text/plain', JSON.stringify(card)); // Set the data being dragged
    setDraggedItem(null);
  };

  const handleDragOver = (e: any) => {
    e.preventDefault();
  };

  return (
    <>
      <FormLabel
        hidden={props.hidden}
        required={props.required}
        description={props.description}>
        {props.label}
      </FormLabel>
      <div className={styles.container}>
        <div className={styles.line}>
          <div className={styles.items} 
              onDragOver={handleDragOver}>
            {items.map((item, i) => (
              <div 
                className={styles.item} 
                key={i}
                draggable={props.draggable}
                onDrop={(e) => handleDrop(e, item)}
                onDragOver={handleDragOver}
                onDragStart={(e) => handleDragStart(e, item)}>

                {item.type === 'Image' && (<img className={styles.media} src={item.url} alt='' />)}
                {item.type === 'Video' && (<video className={styles.media} src={item.url} />)}

                <div className={styles.fog} data-name={'fog'} onClick={(e) => onView(item, i, e)}>
                  <div className={styles.toolbar}>
                    <Svg.Icon.DeleteButton
                      className={styles.button}
                      onClick={() => {
                        setViewerData(item);
                        setVisibleDelete(true);
                      }}
                    />
                  </div>
                </div>
                <input
                  type='hidden'
                  datatype={datatype}
                  name={props.multiple && props.fromForm ? `${props.name}[${i}]` : props.name}
                  value={getValue(item)}
                />
              </div>
            ))}
            {items.length < maxLength && (
              <CCard className={styles.upload} data-uploading={uploading}>
                {!uploading && (
                  <CIcon icon={cilPlus} size='4xl' className={styles.icon} />
                )}
                <input
                  type='file'
                  ref={fileRef}
                  accept={accept}
                  className={styles.input}
                  onChange={onUploadFile}
                  disabled={uploading}
                  required={props.required}
                />
                {uploading && (
                  <div className={styles.progress}>
                    <div className={styles.text}>Загрузка файла...</div>
                    <CProgress
                      value={progress}
                      color='info'
                      variant='striped'
                      animated
                    />
                  </div>
                )}
              </CCard>
            )}
          </div>
        </div>
        <MediaViewer
          items={items}
          activeIndex={activeIndex}
          visible={visibleViewer}
          setVisible={setVisibleViewer}
        />
        <ConfirmationModal
          data={viewerData}
          visible={visibleDelete}
          title={'Удаление'}
          content={'Удалить файл?'}
          confirming={deleting}
          confirmingText={'Удаление'}
          confirmText={'Удалить'}
          unmountOnClose={true}
          onConfirm={onDelete}
          onCancel={() => setVisibleDelete(false)}
        />
      </div>
    </>
  );
}

const getImageFormat = (url?: string): ImageFormat | undefined => {
  if (url) {
    const normalized = url.toLowerCase().trim();
    if (normalized.endsWith('.jpg') || normalized.endsWith('.jpeg')) return 'Jpeg';
    if (normalized.endsWith('.png')) return 'Png';
    if (normalized.endsWith('.gif')) return 'Gif';
    if (normalized.endsWith('.heic')) return 'Heic';
    if (normalized.endsWith('.heif')) return 'Heif';
    if (normalized.endsWith('.webp')) return 'Webp';
    if (normalized.endsWith('.svg')) return 'Svg';
    if (normalized.endsWith('.ico')) return 'Ico';
  }
};

const getVideoFormat = (url?: string): VideoFormat | undefined => {
  if (url) {
    const normalized = url.toLowerCase().trim();
    if (normalized.endsWith('.avi')) return 'Avi';
    if (normalized.endsWith('.mp4')) return 'Mp4';
    if (normalized.endsWith('.mov')) return 'Mov';
  }
};

const getMediaFormat = (url?: string): MediaFormat => (
  getImageFormat(url) ?? getVideoFormat(url) ?? 'Unknown'
);

const getMediaType = (url?: string): MediaType | undefined => {
  const imageFormat = getImageFormat(url);
  if (imageFormat) {
    return 'Image';
  }
  const videoFormat = getVideoFormat(url);
  if (videoFormat) {
    return 'Video';
  }
};

const getMedias = (value: any): IMedia[] => {
  if (value === undefined) {
    return [];
  }
  if (typeof value === 'string') {
    return [{
      url: value,
      type: getMediaType(value),
      format: getMediaFormat(value)
    }];
  }
  if (Array.isArray(value)) {
    let order = -1;
    
    return value.map(value =>{
      order = order + 1;
      return value === 'string'
        ? [{
            url: value,
            type: getMediaType(value),
            format: getMediaFormat(value),
            order: order
      }]
        : { ...value, order: order };
    });
  }
  if (typeof value === 'object') {
    return [{
      ...value,
      type: value.type ?? getMediaType(value.url),
      format: value.format ?? getMediaFormat(value.url)
    }];
  }
  return [];
};


const isFog = (target?: any) => (
  target?.getAttribute &&
  target.getAttribute('data-name') === 'fog'
);